<!--
 * @Description: Description
 * @Author: dpw
 * @Date: 2025-01-216 11:46:23
 * @LastEditors: liutq
 * @LastEditTime: 2025-01-216 11:46:23
-->
<template>
    <div :class="['register-page', $i18n.locale]">
        <div class="navbar">
            <img v-if="isShowBack" src="@/assets/images/password_back_icon.png" class="back" @click.prevent="goBack" />
        </div>
        <div class="page-title">{{ $t('login.account.resetTitle') }}</div>
        <div class="item-title">{{ $t('login.account.password') }}</div>
        <div class="item" v-if="passwordEditor">
            <input autocomplete="off" ref="password" v-model="form.password" @blur="handleBlurResetPassword($event)"
                @focus="handleFouceReset($event)" @input="validatorPassword" @copy.prevent @paste.prevent
                @contextmenu.prevent :type="isAppleDevice ? (showPassword ? 'text' : 'password') : 'text'"
                :class="isAppleDevice ? '' : showPassword ? 'password-visible' : 'password-hidden'" label=""
                :placeholder="$t('login.account.placeholder')" name="password" :border="false" />

            <span class="toggle-password" @click="togglePassword">
                <img :src="showPassword ? require('@/assets/images/eye_show.png') : require('@/assets/images/eye_off.png')
                    " class="eye-icon" />
            </span>
        </div>
        <div class="item" v-if="!passwordEditor">
            <div class="text-view" @click="handleFouceReset">
                {{ password ? password : $t('login.account.placeholder') }}
            </div>
            <span class="toggle-password" @click="togglePassword">
                <img :src="showPassword ? require('@/assets/images/eye_show.png') : require('@/assets/images/eye_off.png')
                    " class="eye-icon" />
            </span>
        </div>
        <div class="item-title" style="margin-top: 24px">
            {{ $t('login.account.confirmPassword') }}
        </div>
        <div class="item" v-if="newPasswordEditor">
            <input autocomplete="off" ref="newPassword" v-model="form.newPassword"
                @blur="handleBlurResetNewPassword($event)" @focus="handleFouceReset1($event)"
                @input="validatorNewPassword" @copy.prevent @paste.prevent @contextmenu.prevent
                :type="isAppleDevice ? (showNewPassword ? 'text' : 'password') : 'text'"
                :class="isAppleDevice ? '' : showNewPassword ? 'password-visible' : 'password-hidden'" label=""
                :placeholder="$t('login.account.placeholder')" name="newPassword" :border="false" />

            <span class="toggle-password" @click="togglePasswordNew">
                <img :src="showNewPassword
                    ? require('@/assets/images/eye_show.png')
                    : require('@/assets/images/eye_off.png')
                    " class="eye-icon" />
            </span>
        </div>
        <div class="item" v-if="!newPasswordEditor">
            <div class="text-view" @click="handleFouceReset1">
                {{ newPassword ? newPassword : $t('login.account.placeholder') }}
            </div>

            <span class="toggle-password" @click="togglePasswordNew">
                <img :src="showNewPassword
                    ? require('@/assets/images/eye_show.png')
                    : require('@/assets/images/eye_off.png')
                    " class="eye-icon" />
            </span>
        </div>
        <div class="password-tips" style="margin-top: 24px">
            {{ $t('login.account.setPasswordTips') }}
        </div>

        <div class="error-tips" v-if="erorTips">{{ erorTips }}</div>
        <van-button :style="{ marginTop: !erorTips ? '47px' : '' }" :disabled="isMobileSubmitDisabled"
            class="custom-btn" @click.prevent="handleMobileSumbit" @keyup.enter="handleMobileSumbit">
            {{ $t('sidebar.btn.confirmBtn') }}
        </van-button>
        <div class="skip" style="margin-top: 24px" v-if="isShowSkip" @click.prevent="skipClick">
            {{ $t('login.account.reset[3]') }}
        </div>
    </div>
</template>


<script>
import { singleCryptographicCheck, cryptographicCheckAndConformance, encryptPassword } from '@/assets/js/util.js'
import { resetPassword } from '@/assets/js/apolloGql.js'

export default {
    components: {},
    data() {
        return {
            isShowBack: true,
            isShowSkip: false,
            isFocus: false,
            showPassword: false,
            showNewPassword: false,
            erorTips: '',
            outTimeStamp: 0,
            form: {
                password: '',
                newPassword: '',
            },
            // 按钮是否正在加载中
            btnState: false,
            passwordEditor: false,
            newPasswordEditor: false,
        }
    },

    computed: {
        isAppleDevice() {
            return /Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)
        },
        // 是否禁用登录按钮
        isMobileSubmitDisabled() {
            return !cryptographicCheckAndConformance(this.form.password, this.form.newPassword)
        },
        password() {
            return this.showPassword ? this.form.password : '•'.repeat(this.form.password.length)
        },
        newPassword() {
            return this.showNewPassword ? this.form.newPassword : '•'.repeat(this.form.newPassword.length)
        },
    },

    watch: {},

    beforeDestroy() { },
    created() {
        const query = this.$route.query
        switch (query.type) {
            case 'registerAccount':
                {
                    // 从注册进入隐藏返回 跳过
                    this.isShowBack = false
                    this.isShowSkip = false
                }
                break
            case 'setPassword':
                {
                    // 设置进入
                    this.isShowBack = true
                    this.isShowSkip = false
                }
                break
            case 'bootSetupPassword':
                {
                    // 设置进入
                    this.isShowBack = false
                    this.isShowSkip = true
                }
                break
        }
    },
    mounted() { },

    methods: {
        /**  为了解决iOS 密码自动填充  下面这些定时是为了解决iOS 兼容的不要嘲笑我，我在这折腾了1天             */
        // 失去焦点后重置背景
        handleBlurResetPassword(e) {
            this.handleBlurReset(e)
            setTimeout(() => {
                this.passwordEditor = false
            }, 50)
        },
        handleBlurResetNewPassword(e) {
            this.handleBlurReset(e)
            setTimeout(() => {
                this.newPasswordEditor = false
            }, 50)
        },
        handleBlurReset(e) {
            const password = this.form.password
            const newPassword = this.form.newPassword
            if (password && newPassword && password.length > 0 && newPassword.length > 0) {
                if (password != newPassword) {
                    this.erorTips = this.$t('login.account.checkRule[4]')
                } else {
                    this.erorTips = ''
                }
            }
            this.outTimeStamp = e.timeStamp
            setTimeout(() => {
                if (this.outTimeStamp === e.timeStamp) {
                    this.resetBackground()
                }
            }, 100)
        },
        handleFouceReset(e) {
            this.passwordEditor = true
            this.newPasswordEditor = false

            setTimeout(() => {
                this.$refs.password.focus()
            }, 130)
            this.outTimeStamp = e.timeStamp
        },
        handleFouceReset1(e) {
            this.newPasswordEditor = true
            this.passwordEditor = false
            setTimeout(() => {
                this.$refs.newPassword.focus()
            }, 130)
            this.outTimeStamp = e.timeStamp
        },
        // 重置iso下键盘收回后底部空白问题
        resetBackground() {
            window.scrollTo(0, 0)
        },
        togglePassword() {
            this.showPassword = !this.showPassword
            this.newPasswordEditor = false
            this.passwordEditor = false
        },
        togglePasswordNew() {
            this.showNewPassword = !this.showNewPassword
            this.newPasswordEditor = false
            this.passwordEditor = false
        },
        validatorPassword() {
            this.form.password = event.target.value.replace(/[\u4e00-\u9fa5]/g, '')
            if (this.form.password.length < 4) {
                this.erorTips = ''
                return
            }
            const data = singleCryptographicCheck(this.form.password)
            if (!data.length) {
                this.erorTips = this.$t('login.account.checkRule[0]')
            } else if (!data.uppercase) {
                this.erorTips = this.$t('login.account.checkRule[1]')
            } else if (!data.lowercase) {
                this.erorTips = this.$t('login.account.checkRule[2]')
            } else if (!data.digit) {
                this.erorTips = this.$t('login.account.checkRule[3]')
            } else {
                this.erorTips = ''
            }
        },
        validatorNewPassword() {
            this.form.newPassword = event.target.value.replace(/[\u4e00-\u9fa5]/g, '')
            const password = this.form.password
            const newPassword = this.form.newPassword
            if (password && newPassword && password.length > 0 && newPassword.length > 0) {
                if (newPassword.length < 8) {
                    this.erorTips = ''
                } else if (password != newPassword) {
                    this.erorTips = this.$t('login.account.checkRule[4]')
                } else {
                    this.erorTips = ''
                }
            }
        },
        onCommand() { },
        // 修改密码
        handleMobileSumbit() {
            if (!this.isMobileSubmitDisabled && !this.btnState) {
                this.btnState = true
                const queryData = this.$route.query
                let mobile = ''
                if (queryData.mobile) {
                    mobile = queryData.areaCode + queryData.mobile
                }
                let email = queryData.email
                let codeStr = queryData.code
                if (queryData.type === 'registerAccount') {
                    // 注册账号进入完善用户信息界面
                    this.toRegister(encryptPassword(this.form.password))
                    return
                } else if (queryData.type === 'setPassword') {
                    // 个人中心进入 从个人信息中获取手机号或者邮箱号
                    const loginfo = JSON.parse(window.localStorage.getItem('loginInfo'))
                    mobile = loginfo.mobile
                    email = loginfo.email
                    console.log('邮箱：' + email + '手机：' + mobile)
                }
                this.$apollo
                    .query({
                        query: resetPassword,
                        fetchPolicy: 'network-only',
                        variables: {
                            mobile: mobile,
                            email: email,
                            code: codeStr,
                            password: encryptPassword(this.form.password),
                            type: this.$route.query.type || '', // 个人用户中心进入设置密码不验证code
                        },
                    })
                    .then((res) => {
                        const data = res.data.resetPassword

                        if (data.code == 200) {
                            this.$toast({
                                message: this.$t('login.account.reset[4]'),
                                duration: 1500,
                            })
                            setTimeout(() => {
                                this.btnState = false
                                this.jumpPage(this.$route.query.type)
                            }, 2000)
                        } else if (data.code === 404) {
                            //该用户不存在
                            this.btnState = false
                            this.erorTips = this.$t('login.account.errorTips[2]')
                        } else if (data.code === 403) {
                            //新密码不能与上一次的密码相同
                            this.btnState = false
                            this.erorTips = this.$t('login.account.reset[2]')
                        } else if (data.code === 413) {
                            // 验证码过期
                            this.btnState = false
                            this.erorTips = this.$t('login.rule.authCode.requiredMsg')
                        }
                    })
                    .catch(() => {
                        this.btnState = false
                    })
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        // 跳过
        skipClick() {
            this.jumpPage('bootSetupPassword')
        },
        // 界面跳转
        jumpPage(type) {
            switch (type) {
                case 'forgotPassword':
                case 'setPassword':
                    this.$router.go(-2)
                    break
                case 'bootSetupPassword':
                    {
                        let path = '/report'
                        // 如果是从别的页面跳转过来的，跳到之前的页面
                        if (this.$route.query.redirect) {
                            path = this.$route.query.redirect // 跳到之前的页面
                        }
                        this.$router.push({
                            path: path,
                        })
                    }
                    break
                default:
                    this.$router.go(-1)
            }
        },
        // 去注册页面
        toRegister(password) {
            const queryData = this.$route.query
            let mobile = queryData.mobile
            let email = queryData.email
            let codeStr = queryData.code
            let areaCode = queryData.areaCode
            let isAutoLogin = queryData.isAutoLogin
            const userInfo = JSON.stringify({
                phone: mobile,
                code: codeStr,
                email: email,
                areaCode: areaCode,
                password: password,
                isAutoLogin: isAutoLogin,
            })
            window.localStorage.setItem('lookUserInfo', userInfo)
            console.log(`开始存储 ${userInfo}`)
            this.$router.push({
                path: '/register',
                query: { redirect: this.$route.query.redirect || '/exception/report' }, // 'to.fullPath'跳转到登录之前页面的路径
            })
        },
    },
}
</script>

<style scoped lang="less">
.register-page {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    background-image: linear-gradient(180deg, #262849, #07090f);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: initial;

    .navbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 18px 15px;
        width: 100%;
        height: 24px;
        top: 0;
        z-index: 100;

        .back {
            width: 24px;
            height: 24px;
        }
    }

    .page-title {
        width: 90%;
        height: auto;
        font-size: 15px;
        color: #8aa3be;
        margin-top: 20px;
    }

    .item-title {
        display: flex;
        width: 90%;
        height: auto;
        font-size: 14px;
        color: #fff;
        padding-left: 24px;
        margin-top: 68px;
        margin-bottom: 8px;
        justify-content: flex-start;
        align-items: center;
    }

    .item {
        width: 90%;
        height: 56px;
        margin: 0 auto;
        border-radius: 4px;
        background: #303c5c;
        padding: 0 16px;
        color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .text-view {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 56px;
            background: transparent;
            font-size: 12px;
            color: #fff;
        }

        input {
            width: 100%;
            height: 56px;
            background: transparent;
            font-size: 12px;
            color: #fff;
        }

        .password-visible {
            color: #fff;
        }

        .password-hidden {
            -webkit-text-security: disc;
            text-security: disc;
        }

        .toggle-password {
            .eye-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .password-tips {
        display: flex;
        width: 89%;
        height: auto;
        font-size: 12px;
        color: #8aa3be;
        margin-top: 16px;
        justify-content: flex-start;
        align-items: center;
    }

    .skip {
        display: flex;
        width: 89%;
        height: auto;
        font-size: 12px;
        background: linear-gradient(to right, #00e3c9, #009fe8);
        /* 渐变颜色 */
        -webkit-background-clip: text;
        /* 仅应用背景到文本 */
        -webkit-text-fill-color: transparent;
        /* 使文字填充透明，显示背景 */
        margin-top: 16px;
        justify-content: center;
        align-items: center;
    }

    .custom-btn {
        width: 200px;
        height: 40px;
        outline: none;
        border-radius: 6px;
        background-image: -webkit-gradient(linear, left top, right top, from(#00e3c9), to(#009fe8));
        background-image: -webkit-linear-gradient(left, #00e3c9, #009fe8);
        background-image: -o-linear-gradient(left, #00e3c9, #009fe8);
        background-image: linear-gradient(to right, #00e3c9, #009fe8);
        font-size: 16px;
        color: #fff;

        &.is-disabled {
            border: none;
            background-image: linear-gradient(#191b31, #191b31),
                /* 底色，即原有的背景 */
                linear-gradient(to right, #06b0a2, #067eb9);
            /* 模拟渐变边框 */
            background-clip: padding-box, border-box;
            background-origin: border-box;
        }

        .spinnerBtn {
            display: flex;
            justify-content: center;
        }

        /deep/ .mint-spinner-snake {
            border: 2px solid transparent;
        }



        &.custom-btn {
            width: 345px;
            height: 40px;
            background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
            border-radius: 6px;
            margin-top: 20px;
        }
    }

    /deep/ .van-button--default {
        border: none !important;
    }

    .error-tips {
        margin: 20px 50px 0 50px;
        font-size: 12px;
        color: #fe6363;
    }
}
</style>
<style>
input[type='password'] {
    /* 覆盖自动填充的背景颜色 */
    -webkit-text-fill-color: #fff;
    /* 文本颜色 */
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    /* 覆盖背景的阴影 */
    transition: background-color 50000s ease-in-out 0s;
    /* 延迟背景颜色变化 */
}

input[type='password']:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    /* 文本颜色 */
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    /* 覆盖背景的阴影 */
}

input[type='text'] {
    /* 覆盖自动填充的背景颜色 */
    -webkit-text-fill-color: #fff;
    /* 文本颜色 */
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    /* 覆盖背景的阴影 */
    transition: background-color 50000s ease-in-out 0s;
    /* 延迟背景颜色变化 */
}

input[type='text']:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    /* 文本颜色 */
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    /* 覆盖背景的阴影 */
}

input[type='password']::-webkit-textfield-decoration-container {
    /* 尝试维持密码隐藏 */
    opacity: 1;
}

input[type='password']:-webkit-autofill::-webkit-textfield-decoration-container {
    /* 当自动填充时，维持密码隐藏 */
    opacity: 1;
}

input[type='password']:focus::-webkit-textfield-decoration-container {
    /* 当输入框获得焦点时，维持密码隐藏 */
    opacity: 1;
}

::-webkit-input-placeholder {
    color: #6c6c6f;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #6c6c6f;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #6c6c6f;
}

:-moz-placeholder {
    color: #6c6c6f;
}
</style>
